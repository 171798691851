<template>
    <NuxtLink to="/">
        <div class="flex flex-row items-center px-4 ">
            <img :src="logoUrl" class="w-[80px]">
            <div class="flex items-center hidden text-lg subpixel-antialiased font-extrabold tracking-wider text-white lg:block"></div>
        </div>
    </NuxtLink>
</template>
<script setup>
const settings = useNuxtApp().$settings

const logoUrl = settings.general_logo
</script>